import utils from '../lib/utils';
import VueFileIcon from './vue-file-icon.vue';
import VueFilePreview from './vue-file-preview.vue';
import VueFileList from './vue-file-list.vue';
import VueFileListItem from './vue-file-list-item.vue';
import FileRecord, { RawFileRecord } from '../lib/file-record';
import Vue from 'vue';

export default Vue.extend({
  props: [
    'accept',
    'auto',
    'averageColor',
    'capture',
    'compact',
    'deletable',
    'disabled',
    'editable',
    'errorText',
    'helpText',
    'linkable',
    'maxFiles',
    'maxSize',
    'meta',
    'multiple',
    'progress',
    'read',
    'readonly',
    'resumable',
    'theme',
    'thumbnailSize',
    'uploadUrl',
    'uploadWithCredentials',
    'value'
  ],
  components: {
    VueFileIcon,
    VueFilePreview,
    VueFileList,
    VueFileListItem,
  },
  data() {
    return {
      fileRecords: [] as FileRecord[],
      rawFileRecords: [] as RawFileRecord[],
      isDragging: false,
      transitionDuration: 300,
      overallProgress: 0,
      uniqueId: '',
    };
  },
  computed: {
    withCredentials(): boolean | undefined {
      return this.uploadWithCredentials;
    },
    canAddMore(): boolean {
      if (!this.hasMultiple) {
        return this.fileRecords.length === 0;
      }
      if (!this.maxFiles) {
        return true;
      }
      return this.fileRecords.length < this.maxFiles;
    },
    helpTextComputed(): string {
      if (this.helpText) {
        return this.helpText;
      }
      return 'Choose ' + (this.hasMultiple ? 'files' : 'file') + ' or drag & drop here';
    },
    isDeletable(): boolean {
      if (typeof this.deletable === 'string') {
        return this.deletable !== 'false';
      }
      return !!this.deletable;
    },
    hasMultiple(): boolean {
      if (typeof this.multiple === 'string') {
        return this.multiple !== 'false';
      }
      if (this.multiple === undefined) {
        return Array.isArray(this.value);
      }
      return !!this.multiple;
    },
    shouldRead(): boolean {
      if (typeof this.read === 'string') {
        return this.read === 'true';
      }
      return !!this.read;
    },
  },
  methods: {
    clickFile(fileInfo ) {
      this.$emit('click-file', fileInfo);
    },
    getFileRecordOrRawInstance(fileRecordOrRaw: FileRecord | RawFileRecord, raw: boolean): FileRecord | RawFileRecord {
      let i;
      if (fileRecordOrRaw instanceof FileRecord) {
        i = this.fileRecords.indexOf(fileRecordOrRaw);
      } else {
        i = this.rawFileRecords.indexOf(fileRecordOrRaw);
      }
      if (i === -1) {
        return fileRecordOrRaw;
      }
      return raw ? this.rawFileRecords[i] : this.fileRecords[i];
    },
    getFileRecordRawInstance(fileRecordOrRaw: FileRecord | RawFileRecord): RawFileRecord {
      return this.getFileRecordOrRawInstance(fileRecordOrRaw, true) as RawFileRecord;
    },
    getFileRecordInstance(fileRecordOrRaw: FileRecord | RawFileRecord): FileRecord {
      return this.getFileRecordOrRawInstance(fileRecordOrRaw, false) as FileRecord;
    },
    equalFiles(file1: File, file2: File): boolean {
      return (
        file1.name === file2.name &&
        file1.size === file2.size &&
        file1.type === file2.type &&
        // file1.lastModifiedDate.getTime() === file2.lastModifiedDate.getTime() &&
        file1.lastModified === file2.lastModified
      );
    },
    isFileAddedAlready(file: File): boolean {
      for (const fileRecord of this.fileRecords) {
        if (this.equalFiles(file, fileRecord.file as File)) {
          return true;
        }
      }
      return false;
    },
    handleFiles(files: File[] | FileList): void {
      if (this.disabled === true || this.readonly === true) {
        return;
      }
      if (this.hasMultiple && !this.canAddMore) {
        return;
      }
      const fileRecords: FileRecord[] = [];
      const filesFiltered: File[] = [];
      // @ts-ignore
      for (let file of files) {
        if (this.hasMultiple && this.isFileAddedAlready(file)) {
          continue;
        }
        filesFiltered.push(file);
      }
      files = filesFiltered;
      if (this.hasMultiple && this.maxFiles && files.length > this.maxFiles - this.fileRecords.length) {
        files = files.slice(0, this.maxFiles - this.fileRecords.length);
      }
      for (const file of files) {
        fileRecords.push(
          new FileRecord(
            {
              file,
            } as RawFileRecord,
            {
              read: this.shouldRead,
              maxSize: this.maxSize,
              accept: this.accept,
              thumbnailSize: this.thumbnailSize,
              averageColor: this.averageColor,
              withCredentials: this.withCredentials,
            }
          )
        );
      }

      if (this.hasMultiple) {
        // splice: for list transitions to work properly
        this.fileRecords.splice(this.fileRecords.length, 0, ...fileRecords);
      } else {
        this.fileRecords = fileRecords;
      }

      FileRecord.readFiles(fileRecords).then((fileRecordsNew: FileRecord[]) => {
        const allFileRecordsRaw = FileRecord.toRawArray(this.fileRecords);
        this.rawFileRecords = allFileRecordsRaw;
        this.$emit('input', Array.isArray(this.value) ? allFileRecordsRaw : allFileRecordsRaw[0]);
        this.$emit('select', FileRecord.toRawArray(fileRecordsNew));
      });
    },
    filesChanged(event: InputEvent): void {
      let files: File[] | FileList = (event.target as HTMLInputElement).files;
      this.$emit('change', event);
      if (!files[0]) {
        return;
      }
      if (!this.hasMultiple) {
        files = [files[0]];
      }
      this.handleFiles(files);
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null; // do not use ''
      }
    },
    drop(event: DragEvent): void {
      event.stopPropagation();
      event.preventDefault();
      this.isDragging = false;
      if (this.disabled === true || this.readonly === true) {
        return;
      }
      if (!event.dataTransfer) {
        return;
      }
      utils.getFilesFromDroppedItems(event.dataTransfer).then((files) => {
        this.$emit('drop', event);
        if (!files || !files[0]) {
          return;
        }
        if (!this.hasMultiple) {
          files = [files[0]];
        }
        this.handleFiles(files);
      });
    },
    removeFileRecord(fileRecordOrRaw: FileRecord | RawFileRecord): void {
      this.$emit('click-remove-file');
      const rawFileRecord = this.getFileRecordRawInstance(fileRecordOrRaw);
      this.$emit('before-delete', rawFileRecord);
      if (!this.uploadUrl || this.auto === false) {
        return;
      }
      this.deleteFileRecord(fileRecordOrRaw);
    },
    deleteFileRecord(fileRecordOrRaw: FileRecord | RawFileRecord): void {
      let i: number;
      if (fileRecordOrRaw instanceof FileRecord) {
        i = this.fileRecords.indexOf(fileRecordOrRaw);
      } else {
        i = this.rawFileRecords.indexOf(fileRecordOrRaw);
      }
      let rawFileRecord = this.rawFileRecords[i];
      this.$emit('input', this.rawFileRecords);
      this.$emit('delete', rawFileRecord);
      this.fileRecords.splice(i, 1);
      this.rawFileRecords.splice(i, 1);
    },

    checkValue(): void {
      let rawFileRecords: RawFileRecord[] = this.value || [];
      rawFileRecords = Array.isArray(rawFileRecords) ? rawFileRecords : [rawFileRecords];

      const fdPromises: Array<Promise<FileRecord>> = [];
      const rawFileRecordsNew: RawFileRecord[] = [];

      for (let i = 0; i < rawFileRecords.length; i++) {
        const existingIndex = this.rawFileRecords.indexOf(rawFileRecords[i]);
        if (existingIndex !== -1) {
          fdPromises.push(Promise.resolve(this.fileRecords[existingIndex]));
          rawFileRecordsNew[i] = this.rawFileRecords[existingIndex];
        } else {
          fdPromises.push(
            FileRecord.fromRaw(rawFileRecords[i], {
              read: this.shouldRead,
              maxSize: this.maxSize,
              accept: this.accept,
              thumbnailSize: this.thumbnailSize,
              averageColor: this.averageColor,
              withCredentials: this.withCredentials,
            })
          );
          rawFileRecordsNew.push(rawFileRecords[i]);
        }
      }

      this.rawFileRecords = rawFileRecordsNew;
      Promise.all(fdPromises).then((fileRecords) => {
        this.fileRecords = fileRecords;
      });
    },
  },
  created() {
    this.uniqueId = new Date().getTime().toString(36) + Math.random().toString(36).slice(2);
    this.checkValue();
  },
  watch: {
    value() {
      this.checkValue();
    },
  },
});
