

















































































import Vue from 'vue';
import mixin from './vue-file-agent-mixin';

export default Vue.extend({
  mixins: [mixin],
  methods: {
    methodThatForcesUpdate(fileRecord) {
      let file = this.fileRecords.find(f => f.file == fileRecord.file);
      if (file) file.id = Math.random();
    }
  }
});
