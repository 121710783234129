

























import {Component, Prop, Vue} from "vue-property-decorator";
import { VueEditor } from "vue2-editor";
import UploadFiles from "@/components/uppload-file/UploadFiles.vue";
import _ from "lodash";

@Component({ components: { VueEditor, UploadFiles } })
export default class TextEditor extends Vue {
  @Prop({ default: null }) readonly value: any;
  @Prop({ default: 'body' }) public dropContainer: string;
  @Prop({ default: 'Click here or drag and drop files to upload a document' }) readonly dropText: string;
  @Prop({ default: false }) public outlined: boolean;
  @Prop({ default: {} }) public fileForDelete: any;
  @Prop({ default: '' }) public errorMessage: string;
  @Prop({ default: null }) public dragAndDropContainer: any;

  dropContainerEl = null;

  $refs!: {
    message: any,
    uploadfiles: any,
  };

  public customToolBar = [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }]
  ];
  public fileInput = null;
  public dropZoneEl = null;

  public get computedMessage() {
    return this.value;
  }
  public set computedMessage(val) {
    this.$emit('input', val);
  }

  public handlePreview(fileInfo) {
    this.$emit('preview-file', fileInfo);
  }

  public messageScrollDown() {
    try {
      this.$refs.message.scrollTop = this.$refs.message.scrollHeight;
    } catch (e) {}
  }

  removeAllFiles() {
    try {
      this.$refs.uploadfiles.removeAllFiles();
    } catch (error) { }
  }

  public onDragEnterHandler(e) {
    e.stopPropagation();
    e.preventDefault();

    this.dropZoneEl.style.display = 'inline-flex';
    this.fileInput.style.display = 'block';
  }

  public onDragLeaveHandler(e) {
    e.stopPropagation();
    e.preventDefault();

    if (_.get(e, 'target.type') === 'file') {
      this.dropZoneEl.style.display = 'none';
      this.fileInput.style.display = 'none';
    }
  }

  public onMouseUp() {
    this.dropZoneEl.style.display = 'none';
    this.fileInput.style.display = 'none';
  }

  public onDropHandler() {
    this.dropZoneEl.style.display = 'none';
    this.fileInput.style.display = 'none';
  }

  mounted() {
    const dropZoneHTML =
      `<div class="file-drop-zone">
        <span class="text-white text-center font__size20 px-3">
          ${ this.dropText }
        </span>
      </div>`;

    this.dropContainerEl = this.dragAndDropContainer ? this.dragAndDropContainer : document.documentElement.querySelector(this.dropContainer);
    this.dropContainerEl?.insertAdjacentHTML('beforeend', dropZoneHTML);

    this.fileInput = document.documentElement.querySelector('.vue-file-agent .file-input');
    this.dropZoneEl = this.dropContainerEl.querySelector('.file-drop-zone');
    this.dropContainerEl.addEventListener('dragenter', this.onDragEnterHandler);
    this.dropContainerEl.addEventListener('drop', this.onDropHandler);
    this.dropContainerEl.addEventListener('dragleave', this.onDragLeaveHandler);
    document.documentElement.addEventListener('mouseup', this.onMouseUp);
  }

  beforeDestroy() {
    this.dropContainerEl.removeEventListener('dragenter', this.onDragEnterHandler);
    this.dropContainerEl.removeEventListener('drop', this.onDropHandler);
    this.dropContainerEl.removeEventListener('dragleave', this.onDragLeaveHandler);
    document.documentElement.removeEventListener('mouseup', this.onMouseUp);
  }
}
