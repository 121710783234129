import VueFileIcon from './vue-file-icon.vue';
import FileRecord, { Options } from '../lib/file-record';
import Vue from 'vue';

export default Vue.extend({
  props: [
    'value',
    'deletable',
    'editable',
    'linkable',
    'errorText',
    'disabled',
    'thumbnailSize',
    'averageColor',
    'withCredentials',
  ],
  components: {
    VueFileIcon,
  },
  data() {
    return {
      fileRecord: {} as FileRecord,
    };
  },
  methods: {
    updateFileRecord() {
      if (this.value instanceof FileRecord) {
        this.fileRecord = this.value;
        return;
      }
      FileRecord.fromRaw(this.value, {
        thumbnailSize: this.thumbnailSize,
        averageColor: this.averageColor,
        withCredentials: this.withCredentials,
      } as Options).then((fileRecord) => {
        this.fileRecord = fileRecord;
      });
      this.fileRecord = FileRecord.fromRawSync(this.value, {
        thumbnailSize: this.thumbnailSize,
        averageColor: this.averageColor,
        withCredentials: this.withCredentials,
      } as Options);
    },

    clearFilename() {
      if (!(this.editable === true && this.isEditInputFocused)) {
        return false;
      }
      (this.$refs.input as HTMLInputElement).value = '';
      this.isEditCancelable = true;
      this.editInputBlured();
      return true;
    },

    removeFileRecord(fileRecord: FileRecord) {
      if (this.clearFilename()) {
        return;
      }
      if (this.disabled === true) {
        return;
      }
      this.$emit('remove', fileRecord);
    },

    dismissError() {
      if (this.fileRecord.error && (this.fileRecord.error.size || this.fileRecord.error.type)) {
        return;
      }
      this.fileRecord.error = false;
    },
  },
  created() {
    this.updateFileRecord();
  },
  watch: {
    value() {
      this.updateFileRecord();
    },
  },
});
