export default {
  'audio': [
    'aif',
    'cda',
    'mid',
    'midi',
    // 'mp3',
    'mpa',
    // 'ogg',
    // 'wav',
    'wma',
    'wpl',
  ],
  'audio-playable': ['mp3', 'ogg', 'wav'],
  'archive': ['7z', 'arj', 'deb', 'pkg', 'rar', 'rpm', 'tar.gz', 'z', 'zip'],
  'disc': [
    // 'bin',
    'dmg',
    'iso',
    'toast',
    'vcd',
  ],
  'database': [
    'csv',
    'dat',
    'db',
    'dbf',
    'log',
    'mdb',
    'sav',
    'sql',
    'tar',
    // 'xml'
  ],
  'executable': ['apk', 'bat', 'bin', 'cgi', 'pl', 'com', 'exe', 'gadget', 'jar', 'py', 'wsf', 'ipa'],
  'font': ['fnt', 'fon', 'otf', 'ttf'],
  'image': [],
  'markup': [
    'asp',
    'aspx',
    'cer',
    'cfm',
    'cgi',
    'pl',
    'css',
    'scss',
    'htm',
    'html',
    'jsp',
    // 'vue',
    'part',
    'php',
    'py',
    'rss',
    'xml',
    'xhtml',
  ],
  'presentation': ['key', 'odp', 'pps', 'ppt', 'pptx'],
  'script': ['c', 'class', 'cpp', 'cs', 'h', 'java', 'js', 'json', 'sh', 'swift', 'vb'],
  'sheet': ['ods', 'xlr', 'xls', 'xlsx'],
  'system': [
    'bak',
    'cab',
    'cfg',
    'cpl',
    'cur',
    'dll',
    'dmp',
    'drv',
    'icns',
    // 'ico',
    'ini',
    'lnk',
    'msi',
    'sys',
    'tmp',
  ],
  'video': [
    '3g2',
    '3gp',
    'avi',
    'flv',
    'h264',
    // 'm4v',
    'mkv',
    // 'mov',
    // 'mp4',
    'mpg',
    'mpeg',
    'rm',
    'swf',
    'vob',
    // 'webm',
    'wmv',
  ],
  'video-playable': ['mp4', 'webm', 'mov'],
  'vue': ['vue'],
  'doc': [
    'doc',
    'docx',
    'odt',
    'rtf',
    'tex',
    // 'txt',
    'wks',
    'wps',
    'wpd',
  ],
  'text': ['txt'],
  'ebook': ['epub'],
  'pdf': ['pdf'],
  'folder': ['folder'],
  'play': ['play'],
};
