import { getFilesFromDroppedItems } from './drop-handler';

export type RGBA = [number, number, number, number];
export interface VideoThumbnail {
  url: string;
  color?: RGBA;
  width: number;
  height: number;
}
export interface ImageThumbnail {
  image: HTMLImageElement;
  url: string;
  color?: RGBA;
}

enum ImageOrientation {
  NORMAL = 1,
  UPSIDE_DOWN = 6,
}

class Utils {
  public arrayMove(arr: any[], previousIndex: number, newIndex: number): any[] {
    // https://github.com/Jexordexan/vue-slicksort/blob/master/src/utils.js
    const array = arr.slice(0);
    if (newIndex >= array.length) {
      let k = newIndex - array.length;
      while (k-- + 1) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
    return array;
  }

  public getAverageColor(arr: Uint8ClampedArray): RGBA | undefined {
    const bytesPerPixel = 4;
    const arrLength = arr.length;
    if (arrLength < bytesPerPixel) {
      return;
    }
    const step = 5;
    const len = arrLength - (arrLength % bytesPerPixel);
    const preparedStep = (step || 1) * bytesPerPixel;

    let redTotal = 0;
    let greenTotal = 0;
    let blueTotal = 0;
    let alphaTotal = 0;
    let count = 0;

    for (let i = 0; i < len; i += preparedStep) {
      const alpha = arr[i + 3];
      const red = arr[i] * alpha;
      const green = arr[i + 1] * alpha;
      const blue = arr[i + 2] * alpha;

      redTotal += red;
      greenTotal += green;
      blueTotal += blue;
      alphaTotal += alpha;
      count++;
    }

    return alphaTotal
      ? [
          Math.round(redTotal / alphaTotal),
          Math.round(greenTotal / alphaTotal),
          Math.round(blueTotal / alphaTotal),
          Math.round(alphaTotal / count),
        ]
      : [0, 0, 0, 0];
  }

  public getDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (!(event.target && event.target.result)) {
          return resolve('');
        }
        resolve(event.target.result as string);
      };
      reader.readAsDataURL(file);
    });
  }

  public getSizeFormatted(bytes: number) {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 B';
    }
    let i = Math.floor(Math.log(bytes) / Math.log(1024));
    i = parseInt('' + i, 10);
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  public getSizeParsed(size: string): number {
    size = ('' + size).toUpperCase();
    const matches = size.match(/([\d|.]+?)\s*?([A-Z]+)/);
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (!matches) {
      return parseFloat(size);
    }
    const i = sizes.indexOf(matches[2]);
    if (i === -1) {
      return parseFloat(size);
    }
    return parseFloat(matches[1]) * Math.pow(1024, i);
  }

  public getColorForText(text: string): string {
    const getHashCode = (value: string) => {
      let hash = 0;
      if (value.length === 0) {
        return hash;
      }
      for (let i = 0; i < value.length; i++) {
        // tslint:disable-next-line
        hash = value.charCodeAt(i) + ((hash << 5) - hash);
        // tslint:disable-next-line
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
    };
    const intToHSL = (value: number) => {
      const h = value % 360;
      const s = value % 100;
      const l = 50;
      return 'hsl(' + h + ',' + s + '%,' + l + '%, 0.75)';
    };
    return intToHSL(getHashCode(text.toLowerCase()));
  }

  public validateType(file: File, accept?: string): boolean {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
    // https://gitlab.com/meno/dropzone/blob/master/src/dropzone.js#L2511
    if (!accept) {
      return true;
    } // If there are no accepted mime types, it's OK
    const acceptedFiles = accept.split(',');

    const mimeType = file.type;
    const baseMimeType = mimeType.replace(/\/.*$/, '');

    for (let validType of acceptedFiles) {
      let trimmedValidType = validType.trim();
      if (trimmedValidType.charAt(0) === '.') {
        // extension
        if (file.name.toLowerCase().indexOf(trimmedValidType.toLowerCase(), file.name.length - trimmedValidType.length) !== -1) {
          return true;
        }
      } else if (/\/\*$/.test(trimmedValidType)) {
        // This is something like a image/* mime type
        if (baseMimeType === trimmedValidType.replace(/\/.*$/, '')) {
          return true;
        }
      } else {
        if (mimeType === trimmedValidType) {
          return true;
        }
      }
    }

    return false;
  }

  public validateSize(file: File, maxSize?: string): boolean {
    if (!maxSize) {
      return true;
    }
    const bytes = this.getSizeParsed(maxSize);
    return file.size <= bytes;
  }

  public getFilesFromDroppedItems(dataTransfer: DataTransfer) {
    return getFilesFromDroppedItems(dataTransfer);
  }
}

export default new Utils();
