<template src="./UploadFiles.html"></template>

<script>
import VueFileAgent from "./vue-file-agent/index";
import { Vue } from "vue-property-decorator";
import {
  ALLOWED_FILE_EXTENSIONS,
  FILE_UPLOADING_ERROR_MESSAGES,
} from "@/constants";

Vue.use(VueFileAgent);

export default {
  props: ["fileForDelete", "errorMessage"],
  data: function () {
    return {
      allowedFileExtensions: ALLOWED_FILE_EXTENSIONS,
      allowedFileSize: "30 MB",
      maxFiles: "20",
      isClickRemoveFile: false,
      fileRecords: this.fileRecords || [],
    };
  },
  methods: {
    filesSelected: function (fileRecordsNewlySelected) {
      this.$emit('select', fileRecordsNewlySelected)
      let validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      validFileRecords.forEach((f) => this.$parent.$parent.file.push(f.file));
    },
    onBeforeDelete: function (fileRecord) {
      let i = this.$parent.$parent.file.indexOf(fileRecord.file);
      if (i !== -1) {
        this.$parent.$parent.file.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
      }
    },
    clickFile: function (fileInfo) {
      if (_.get(fileInfo, 'errorMessage', '')) return;
      if (!this.isClickRemoveFile) this.$emit("preview-file", fileInfo);
      this.isClickRemoveFile = false;
    },
    clickRemoveFile: function () {
      this.isClickRemoveFile = true;
    },
    removeAllFiles: function () {
      this.fileRecords = [];
    },
    showErrorMessageOnFiles: function (message) {
      this.getErrorUploadFileNames(message);
      this.fileRecords.forEach((file, indx) => {
        if (_.get(file.file, 'errorMessage', '')) {
          this.fileRecords[indx].errorValidationMessage = file.file.errorMessage;
          this.$refs.vueFileAgent.methodThatForcesUpdate(
            this.fileRecords[indx]
          );
        }
      });
    },
    getErrorUploadFileNames: function (errorMessage) {
      if (errorMessage.includes("infected")) {
        let messages = errorMessage.split("!").filter((m) => m.length);
        for (const f of messages) {
          let fileName = f.match(/File\s([\w().,\-:;@#$%^&*\[\]"'+–\/\\°⁰!?{}|'~\s\W]+)\sis\sinfected/i)[1];
          this.fileRecords.forEach((file, indx) => {
            if (file.file.name == fileName)
              this.fileRecords[indx].file.errorMessage =
                FILE_UPLOADING_ERROR_MESSAGES.VIRUS;
          });
        }
      }
      if (errorMessage.includes("Unsupported")) {
        let messages = errorMessage.split("!").filter((m) => m.length);
        for (const fm of messages) {
          let fileNames = fm.match(/types:\s([\w().,\-:;@#$%^&*\[\]"'+–\/\\°⁰!?{}|'~\s\W]+)/i)[1];
          fileNames = fileNames.split(",");
          fileNames.forEach((f) => {
            let fileName = f.trim();
            this.fileRecords.forEach((file, indx) => {
              if (file.file.name == fileName)
                this.fileRecords[indx].file.errorMessage =
                  FILE_UPLOADING_ERROR_MESSAGES.UNSUPPORTED;
            });
          });
        }
      }
    },
  },
  watch: {
    fileForDelete(deletedFileInfo) {
      if (deletedFileInfo)
        this.fileRecords = this.fileRecords.filter(
          (f) => f.file !== deletedFileInfo
        );
    },
    errorMessage(message) {
      this.showErrorMessageOnFiles(message);
    },
  },
};
</script>
<style src="./style.css"></style>